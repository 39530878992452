import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import { MessageBox } from 'element-ui';
import store from '@/store/' 
Vue.use(store)

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const baseRoutes = [
  // {
  //   path: '/',
  //   name: 'index',
  //   component: () => import('@/views/Index.vue'),
  // },
  {
    path: '/:plat_type/index',
    name: 'index',
    component: () => import('@/views/Index.vue'),
  },
  {
    path: '/:plat_type/molecule',
    name: 'molecule',
    component: () => import('@/views/Molecule.vue'),
  },


  {
    path: '/:plat_type/lessonTuoyu',
    name: 'lessonTuoyu',
    component: () => import('@/views/LessonTuoyu.vue'),
  },
 
  {
    path: '/:plat_type/cert',
    name: 'cert',
    component: () => import('@/views/Cert.vue'),
  },
  {
    path: '/:plat_type/search2',
    name: 'search',
    component: () => import('@/views/Search2.vue'),
  },

  {
    path: '/:plat_type/detail',
    name: 'detail',
    meta: { 
      requireAuth: true

     },
    component: () => import('@/views/Detail.vue'),
  },
  {
    path: '/:plat_type/detail2',
    name: 'detail2',
    meta: { 
      requireAuth: true

     },
    component: () => import('@/views/Detail2.vue'),
  },

  {
    path: '/:plat_type/player2',
    name: 'player2',
    meta: { 
      requireAuth: true

     },
    component: () => import('@/views/Player2.vue'),
  },
 
  {
    path: '/:plat_type/study',
    name: 'study',
    component: () => import('@/views/Study.vue'),
  },
  {
    path: '/:plat_type/examin',
    name: 'examin',
    component: () => import('@/views/Examin.vue'),
  },
  {
    path: '/:plat_type/examinList',
    name: 'examinList',
    component: () => import('@/views/ExaminList.vue'),
  },
  {
    path: '/:plat_type/examinResult',
    name: 'examinResult',
    component: () => import('@/views/ExaminResult.vue'),
  },
  {
    path: '/:plat_type/examinResultImitate',
    name: 'examinResultImitate',
    component: () => import('@/views/ExaminResultImitate.vue'),
  },
  {
    path: '/:plat_type/examinVideoResult',
    name: 'examinVideoResult',
    component: () => import('@/views/ExaminVideoResult.vue'),
  },
  {
    path: '/:plat_type/examinVideoUpload',
    name: 'examinVideoUpload',
    component: () => import('@/views/ExaminVideoUpload.vue'),
  },
  {
    path: '/:plat_type/examinResultDetail',
    name: 'examinResultDetail',
    component: () => import('@/views/ExaminResultDetail.vue'),
  },
  {
    path: '/:plat_type/certForm',
    name: 'certForm',
    component: () => import('@/views/CertForm.vue'),
  },
  {
    path: '/:plat_type/certList',
    name: 'certList',
    component: () => import('@/views/CertList.vue'),
  },
  {
    path: '/:plat_type/myCenter',
    name: 'myCenter',
    component: () => import('@/views/MyCenter.vue'),
  },
  {
    path: "*",
    name: "404",
    redirct: "/404",
    component: () => import("@/views/NotFound.vue"),
    hidden: true
  }
]

const router = new VueRouter({
  scrollBehavior: (to, from, savedPosition) => {
    console.log('111')
    window.scrollTo(0, 0);
  },
  // mode: 'history',
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: baseRoutes
});

function is_weixin() {
  var ua = navigator.userAgent.toLowerCase();
  if (ua.match(/MicroMessenger/i) == "micromessenger") {
    return true;
  } else {
    return false;
  }
}

router.beforeEach(async (to, from, next) => {
  NProgress.start();
  const user = localStorage.getItem('user');
  console.log(to)
  if (user || !to.meta.requireAuth || to.name=='player2' && to.query.id == '747') {
    next()
    NProgress.done();
  } else {
    console.log('禁止查看')
    store.commit('changeLoginShow',true)
    
    // next()
    NProgress.done();
  }
})

export { router }

import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import api from '../utils/api'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [
    // 默认存储在localStorage上
    createPersistedState({
      key: 'vuex',
      // storage: 'window.localStorage'
    })
  ],
  state: {
    currentVideoInfo:null,
    isLoginShow:false,
    studyInfo:{},
    headerActive: 'index',
    platMap: null,
    isLogin:false,
    plat: {
      id: 205,
      name: "讲坛",
      logo: 'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/zhongtuo/zhongtuoLogo.png',
      faviconurl: 'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/tuoyu/favicon_tuoyu.ico',
    },
    plat_id: 205,
    vipInfo:null,
    user:null,
    VIPShow:true,
  },
  mutations: {
    // 重置一些直接在state里写死的值，以防止直接改state里的数据，浏览器缓存没有更新
    SET_INIT:(state,info)=>{
      state[info.name] = info.data
    },
    VIPShow:(state,info)=>{
      state.VIPShow = info
    },
    SET_USER:(state,info)=>{
      state.user = info
    },
    SET_VIP:(state,info)=>{
      state.vipInfo = info
    },
    SET_VIDEO:(state,info) => {
        state.currentVideoInfo = info
    },
    changeLoginShow:(state,statue) => {
        state.isLoginShow = statue
    },
    SET_LOGINSTATUS:(state,info)=>{
      console.log('更改登录状态：'+info);
      state.isLogin = info
    },
    SET_STUDY: (state, info) => {
        state.studyInfo = info
    },
    SET_PLAT: (state, plat) => {
      console.log(plat)
      localStorage.setItem('plat',plat)
      state.plat = state.platMap[plat];
      console.log(state.plat)
      console.log('SET_PLAT', plat, state.platMap[plat].logo)
      state.plat_id = state.platMap[plat].id;
      document.title = state.platMap[plat].name;
      // let faviconurl = state.platMap[plat].faviconurl;
      // var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
      // link.type = 'image/x-icon';
      // link.rel = 'shortcut icon';
      // link.href = faviconurl;
      // document.getElementsByTagName('head')[0].appendChild(link);
      // console.log(state.plat)
    },
    SET_HEADER: (state, plat) => {
      state.headerActive = plat
    }
  },
  actions: {
    async getStudy(context){
      if(context.state.plat_id==119 || context.state.plat_id==171){
        let res = await api({
          method: 'post',
          url: '/api/flatform/record/list',
          data: {
             flat_id: context.state.plat_id,
             category_id: 0
          }
       });
       if (res.code == 0) {
        context.commit('SET_STUDY',res.data)
       }
      }else{
        let res = await api({
          method: 'get',
          url: '/api/flat/record/list',
       });
       if (res.code == 0) {
        context.commit('SET_STUDY',res.data)
       }
      }
      
    },
   
    async getVIPInfo(context) {
      if(context.state.plat_id==119 || context.state.plat_id==171){
        let res = await api({
          method: "get",
          url: "/api/platform/university/index",
          data: {
            plat_id: context.state.plat_id,
            terminal: 1
          },
        });
        if (res.code == 0) {
          context.commit('SET_VIP',res.data.plat_vip)
        }
      }else{
        let res = await api({
          method: "get",
          url: "/api/platform/pc/index",
          data: {
            plat_id: context.state.plat_id,
            terminal: 1
          },
        });
        if (res.code == 0) {
          context.commit('SET_VIP',res.data.plat_vip)
        }
      }
       
    },
  },
  modules: {
  }
})
